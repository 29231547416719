<template>
    <workspace-wrapper>
        <template #header>
            <default-slot-header :bet="stateData.currentSpinResult.bet"
                                 :lines="stateData.currentSpinResult.linesCount"
                                 :stake="stateData.currentSpinResult.stake"
            />
        </template>

        <template #workspace>
            <multi-reversed-layout-workspace :layouts="stateData.currentSpinResult.layoutHistory"
                                             :symbols-path="symbolsPath"
            />
        </template>
    </workspace-wrapper>
</template>

<script>
import WorkspaceWrapper from "../Common/WorkspaceWrapper";
import DefaultSlotHeader from "./Common/Header/DefaultSlotHeader";
import MultiReversedLayoutWorkspace from "./Common/MultiReversedLayoutWorkspace";

export default {
    props: {
        stateData: {
            type: Object,
            required: true,
            default() {
                return {
                    currentSpinResult: {
                        balanceBefore: null,
                        bet: null,
                        linesCount: null,
                        stake: null,
                        layout: null,
                        layoutHistory: [],
                    }
                }
            }
        }
    },
    data() {
        return {
            symbolsPath: 'cairocasino'
        }
    },
    components: {
        MultiReversedLayoutWorkspace,
        WorkspaceWrapper,
        DefaultSlotHeader
    }
}
</script>

<style scoped>

</style>
