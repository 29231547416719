<template>
    <div>
        <b-tabs expanded>
            <b-tab-item v-for="(layout, key) in layouts" :key="key"

                        :label="(key + 1).toString()"
            >
                <table>
                    <tr v-for="(row, reelIndex) in layout"
                        :key="reelIndex"
                    >
                        <td v-for="(symbol, rowIndex) in row"
                            :key="rowIndex"
                        >
                            <div :style='{ backgroundImage: `url( ${imagePath(symbol)} )` }'
                                 class="layout__symbol"
                            />
                        </td>
                    </tr>
                </table>
            </b-tab-item>
        </b-tabs>
    </div>
</template>

<script>
export default {
    props: {
        layouts: {
            type: Array,
            required: true,
        },
        symbolsPath: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            symbol: {
                size: 80
            }
        }
    },
    methods: {
        getRows() {
            return this.layouts[0].length;
        },
        getReels() {
            return this.layouts[0][0].length;
        },
        imagePath(symbol) {
            try {
                return require(`@/assets/games/slots/${this.symbolsPath}/${symbol}.png`);
            } catch (e) {
                return require(`@/assets/games/slots/default/${symbol}.png`);
            }
        }
    },
    mounted() {
        this.$store.dispatch('workspace/setHeight', this.getRows() * this.symbol.size * 1.3);
        this.$store.dispatch('workspace/setWidth', this.getReels() * this.symbol.size * 1.1);
    }
}
</script>

<style scoped>
table {
    border-collapse: collapse;
    margin: auto;
}

td {
    border: 1px solid #dee2e6 !important;
}

.layout__symbol {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 80px;
    height: 80px;

    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
}
</style>
